// animations.ts
import { animate, group, keyframes, query, style, transition, trigger } from '@angular/animations';

export const viewTransitionMain = trigger('viewTransitionMain', [
  transition('* <=> *', [
    query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
    group([
      query(':enter', [
        animate('200ms', keyframes([
          style({ opacity: 0, transform: 'translateY(24px)', offset: 0 }),
          style({ opacity: 1, transform: 'translateY(0)', offset: 1 }),
        ])),
      ], { optional: true }),
      query(':leave', [
        animate('90ms', style({ opacity: 0 })),
        animate('200ms', keyframes([
          style({ transform: 'translateY(0)', offset: 0 }),
          style({ transform: 'translateY(-24px)', offset: 1 }),
        ])),
      ], { optional: true }),
    ]),
  ]),
]);


export const viewTransitionSub = trigger('viewTransitionSub', [
  transition(':enter', [
    style({ position: 'fixed', width: '100%' }),
    animate('300ms', keyframes([
      style({ transform: 'translateX(100%)', offset: 0 }),
      style({ transform: 'translateY(0)', offset: 1 }),
    ])),
  ]),
  transition(':leave', [
    style({ position: 'fixed', width: '100%' }),
    animate('300ms', keyframes([
      style({ transform: 'translateY(0)', offset: 0 }),
      style({ transform: 'translateY(-100%)', offset: 1 }),
    ])),
  ]),
]);

export const viewTransitionContent = trigger('viewTransitionContent', [
  transition(':enter', [
    animate('200ms', keyframes([
      style({ opacity: 0, transform: 'translateY(24px)', offset: 0 }),
      style({ opacity: 1, transform: 'translateY(0)', offset: 1 }),
    ])),
  ]),
  transition(':leave', [
    animate('90ms', style({ opacity: 0 })),
    animate('200ms', keyframes([
      style({ transform: 'translateY(0)', offset: 0 }),
      style({ transform: 'translateY(-24px)', offset: 1 }),
    ])),
  ]),
]);
